<template>
  <b-card class="discount-code-card py-3 px-3">
    <p class="text-primary p-0 m-0 font-size-24 mb-2 capitalize">
      {{ $t("payment.discountCode") }}
    </p>
    <div class="d-flex align-items-center justify-content-between">
      <input-form
        class="m-0 input-payment w-100"
        name="id"
        :placeholder="$t('payment.discountCode')"
        v-model="code"
      >
        <template #append>
          <button
            v-if="code"
            @click="removeCopoun"
            class="discount-code__clear"
          >
            <i class="las la-times"></i>
          </button>
        </template>

        <template #prepend>
          <i class="las la-tag"></i>
        </template>
      </input-form>

      <app-button
        :disabled="!code"
        :loading="loading"
        @click.native="addCoupon"
        style="width: 100px; border-radius: 0px !important"
      >
        {{ $t("payment.activate") }}
      </app-button>
    </div>
    <p v-if="value" class="text-success pt-2 m-0 font-size-14 mb-2 capitalize">
      {{ $t("payment.successDiscount") }} {{ value }}
      {{ $t("main.sar") }}
    </p>
  </b-card>
</template>

<script>
import appButton from "../../../../../components/buttons/appButton.vue";
export default {
  components: { appButton },
  props: {
    discountCode: {
      type: String,
      default: "",
      required: false,
    },
    discountValue: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      code: this.discountCode,
      value: this.discountValue,
      loading: false,
      error: "",
    };
  },

  methods: {
    getCoupon() {
      this.$store.getters["cart/getCoupon"];
    },
    async addCoupon() {
      this.loading = true;
      this.value = 0;
      try {
        const { data } = await this.$store.dispatch("cart/applyCopoun", {
          cart_id: +this.$route.params.id,
          code: this.code,
        });
        this.value = data.data.discount_amount;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async removeCopoun() {
      try {
        await this.$store.dispatch("cart/removeCopoun", {
          cart_id: +this.$route.params.id,
          code: this.code,
        });
        this.value = 0;
        this.code = "";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-code {
  &__clear {
    background: transparent;
    border: none;
    outline: none;
  }
}
</style>