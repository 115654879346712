<template>
  <b-modal
    id="address-modal"
    size="lg"
    :title="!edit ? $t('payment.addAddress') : $t('payment.editAddress')"
    centered
    hide-footer
  >
    <ValidationObserver ref="addressForm" v-slot="{ handleSubmit }">
      <b-form
        class="position-relative add-address"
        @submit.prevent="handleSubmit(addAddress)"
      >
        <b-row>
          <b-col md="6">
            <input-form
              class="mb-3"
              :validate="'required'"
              :name="$t('payment.clientName')"
              :label="$t('payment.clientName')"
              :placeholder="$t('payment.clientName')"
              v-model="location.name"
            />
          </b-col>
          <b-col md="6">
            <input-form
              class="mb-3"
              :name="$t('payment.phoneNumber')"
              :validate="'required|numeric|min:9|max:11'"
              :label="$t('payment.phoneNumber')"
              :placeholder="$t('payment.phoneNumber')"
              v-model="location.phone"
            />
          </b-col>
          <b-col md="6">
            <main-select
              :reduce="(city) => city.id"
              :options="cities"
              v-model="city_id"
              :validate="'required'"
              :labelTitle="$t('payment.city')"
              label="name"
              :placeholder="$t('payment.city')"
              :name="$t('payment.city')"
            />
          </b-col>
          <b-col md="6">
            <main-select
              :reduce="(area) => area.id"
              :options="areas"
              v-model="area_id"
              :labelTitle="$t('payment.area')"
              :placeholder="$t('payment.area')"
              label="name"
              :name="$t('payment.area')"
              :validate="'required'"
            />
          </b-col>
          <b-col md="6">
            <input-form
              class="mb-3"
              :name="$t('payment.address')"
              :validate="'required'"
              :label="$t('payment.address')"
              :placeholder="$t('payment.address')"
              v-model="location.address"
            />
          </b-col>
          <b-col md="6">
            <input-form
              class="mb-3"
              :name="$t('payment.title')"
              :validate="'required'"
              :label="$t('payment.title')"
              :placeholder="$t('payment.title')"
              v-model="location.title"
            />
          </b-col>

          <b-col md="12">
            <GmapMap
              :center="mapCenter"
              :zoom="mapZoom"
              style="width: 100%; height: 300px"
              @click="updateCoordinates"
              :options="{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
              }"
            >
              <GmapMarker
                :position="mapCenter"
                :draggable="true"
                :clickable="true"
                @dragend="updateCoordinates"
              />
            </GmapMap>
          </b-col>
        </b-row>

        <app-button class="mt-3" :loading="sumbitLoading"
          ><span>{{
            !edit ? $t("payment.addAddress") : $t("payment.editAddress")
          }}</span>
        </app-button>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import AppButton from "@/components/buttons/appButton.vue";
import citiesMixin from "../../mixins/getCities";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import { core } from "@/config/pluginInit";

export default {
  components: { AppButton },
  mixins: [citiesMixin],
  props: {
    address: {
      default: "",
      type: Object,
    },
  },
  data() {
    return {
      sumbitLoading: false,
      location: {},
      edit: false,
      mapZoom: 12,
    };
  },
  computed: {
    mapCenter() {
      return this.$store.getters["address/userCoordinates"];
    },
  },
  methods: {
    async addAddress() {
      this.sumbitLoading = true;
      const addressObj = {
        ...this.location,
        city_id: this.city_id,
        area_id: this.area_id,
        lat: "" + this.mapCenter.lat,
        long: "" + this.mapCenter.lng,
      };
      try {
        let res;
        if (this.edit) {
          res = await this.$store.dispatch("address/editAddress", addressObj);
        } else {
          res = await this.$store.dispatch("address/addAddress", addressObj);
        }
        core.showSnackbar("success", res.data.message);
        this.$bvModal.hide("address-modal");
      } catch (e) {
        core.showSnackbar("error", e.response.data.message);
      } finally {
        this.sumbitLoading = false;
      }
    },
    updateCoordinates(e) {
      const { lat, lng } = e.latLng;
      this.$store.commit("address/UPDATE_USER_COORDINATES", {
        lat: lat(),
        lng: lng(),
      });
    },
  },
  watch: {
    address(val) {
      if (Object.keys(val).length) {
        this.edit = true;
        this.location = val;
        this.city_id = val.city.id;
        this.$nextTick(() => {
          this.area_id = val.area.id;
        });
      } else {
        this.edit = false;
        this.location = {};
        this.city_id = null;
        this.area_id = null;
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>