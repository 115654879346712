<template>
  <b-container>
    <empty-cart class="pt-4" v-if="cartItem?.cart_products == 0" />
    <template v-else>
      <b-row class="mt-5 payment-actions-row flex-row-reverse">
        <b-col xl="5" class="mb-4">
          <discount-widget
            :discountValue="cartItem.discount_amount"
            :discountCode="cartItem?.coupon?.code"
          />

          <order-info />
        </b-col>
        <b-col xl="7" class="payment-info mb-5">
          <address-list class="mb-4" selectedMode />

          <div class="shipping-companies mb-4">
            <h4 class="text-primary">
              {{ $t("payment.shippingCampanies") }}
            </h4>
            <b-skeleton-wrapper
              class="mt-2"
              :loading="shippingCampaniesLoading"
            >
              <template #loading>
                <b-skeleton
                  class="mb-2"
                  width="100%"
                  height="50px"
                ></b-skeleton>
                <b-skeleton
                  class="mb-2"
                  width="100%"
                  height="50px"
                ></b-skeleton>
                <b-skeleton
                  class="mb-2"
                  width="100%"
                  height="50px"
                ></b-skeleton>
              </template>
              <select-card
                v-model="company_shipping"
                v-for="company in shippingCompanies"
                :key="company.id"
                :item="company"
                value-key="object"
              >
                <div class="d-flex flex-column">
                  <p class="mb-0 font-weight-bold">{{ company.name }}</p>
                  <p class="mb-0">
                    {{ company.shipping_fees }}
                    {{ $t("main.sar") }}
                  </p>
                </div>
              </select-card>
            </b-skeleton-wrapper>
          </div>

          <div class="d-flex justify-content-between align-content-center">
            <h4 class="text-primary my-2">
              {{ $t("payment.paymentOptions") }}
            </h4>
          </div>
          <payment-component @pay="addOrder" :four-grid="true" />
        </b-col>
      </b-row>
    </template>

    <loader />
  </b-container>
</template>

<script>
import { core } from "@/config/pluginInit";
import PaymentCard from "../../components/cart/paymentCard";
import addNewPaymentModel from "@/modules/servicePages/marketplace/components/cart/paymentModels/addNewPaymentModel";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";

import paymentComponent from "@/components/payment";
import cartFunctions from "@/mixins/cartFunctions";
import paymentMixin from "@/mixins/payWithCard";
import OrderInfo from "../../components/checkout/orderInfo.vue";
import Loader from "../../../../../components/core/loader/Loader.vue";
import EmptyCart from "../../components/cart/emptyCart.vue";
import DiscountWidget from "../../components/checkout/discountWidget.vue";
import SelectCard from "@/components/selectCard.vue";
import AddressList from "@/components/adderss/addressList.vue";

export default {
  name: "payment-actions",
  components: {
    PaymentCard,
    addNewPaymentModel,
    paymentComponent,
    OrderInfo,
    Loader,
    EmptyCart,
    DiscountWidget,
    SelectCard,
    AddressList,
  },
  mixins: [cartFunctions, paymentMixin],
  data() {
    return {
      allItems: {},
      company_shipping: null,
      shippingCompanies: [],
      shippingCampaniesLoading: false,
    };
  },
  computed: {
    cartItem() {
      return this.$store.getters["cart/getStoreDetailsAndProducts"](
        this.$route.params.id
      );
    },
    orderData() {
      return {
        cartId: this.$route.params.id,
        customer_addres_id: this.selectedAddress,
        company_shipping_id: this.company_shipping.ref,
      };
    },
    addresses() {
      return this.$store.getters["address/addressesList"];
    },
    selectedAddress() {
      return this.$store.getters["address/selectedAddress"];
    },
  },

  methods: {
    async getShippingCompanies() {
      this.shippingCampaniesLoading = true;
      const { data } = await marketPlace.getShippingCompanies({
        cart_id: this.$route.params.id,
        customer_address_id: this.selectedAddress,
      });
      this.shippingCompanies = data.data;
      this.shippingCampaniesLoading = false;
    },
    async addOrder(type) {
      const shippingErrorMessage =
        this.$i18n.locale === "ar"
          ? "يجب أختيار شركة شحن"
          : "You must select the address";
      if (!this.company_shipping.id)
        return core.showSnackbar("error", shippingErrorMessage);
      this.$store.commit("Setting/setLoading", true);
      try {
        type == "wallet"
          ? await this.payWithWallet()
          : await this.payWithCard();
      } finally {
        this.$store.commit("Setting/setLoading", false);
      }
    },
    async payWithCard() {
      const { data } = await marketPlace.payOrder({
        ...this.orderData,
        type: "card",
      });
      window.open(data.checkout_url, "_self");
    },
    async payWithWallet() {
      try {
        const { data } = await marketPlace.payOrder({
          ...this.orderData,
          type: "wallet",
        });
        core.showSnackbar("success", data.message);
        this.$store.commit("cart/DELETE_STORE", this.orderData.cartId);
        this.$router.push({ name: "user-marketplace" });
      } catch (err) {
        core.showSnackbar("error", err.response.data.message);
      }
    },
  },
  watch: {
    selectedAddress: {
      handler: async function (val) {
        this.company_shipping = {
          id: null,
          name: null,
          shipping_fees: 0,
          ref: null,
        };

        val && this.getShippingCompanies();
      },
      immediate: true,
    },
    company_shipping: {
      handler: function (val) {
        this.$store.commit("cart/SET_SHIPPING_COMPANY", {
          cartId: this.$route.params.id,
          company: val,
        });
      },
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!localStorage.getItem("userToken")) {
      next({ name: "auth" });
    } else next();
  },
};
</script>

<style lang="scss">
.input-payment {
  background-color: #f4f4f4 !important;
  border-radius: 0 !important;
  border: 0 !important;
}

.address-card {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &.active-address {
    border: 1px solid var(--iq-primary) !important;
  }
}

.payment-store-img {
  width: 62px !important;
  height: 62px !important;
  border-radius: 10px;
  overflow: hidden;
}

.payment-store-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.payment-mada-img {
  height: 35px;
  width: 55px;
}

.payment-mada-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}

input[name*="plain-inline"] {
  accent-color: green;
}

.w-35 {
  width: 35% !important;
}

@media (min-width: 768px) {
  .payment-info {
    position: sticky !important;
    top: 10px;
    height: fit-content !important;
  }
}

.order-select {
  background: #f2f2f2;
}

.edit-btn {
  border-radius: 4px !important;
}

.store-detail-btn {
  height: 45px;
  border: none !important;
  background: #1f453e !important;
}
</style>

