<template>
  <b-card class="order-info mt-4 py-3 px-3">
    <h4>
      {{ $t("payment.orderInfo") }}
    </h4>
    <hr />
    <div class="order-info__store d-flex align-items-center gap_1">
      <img
        width="75"
        height="75"
        :src="cartItem.store.logo"
        :alt="cartItem.name"
        style="border-radius: 50%"
      />
      <h4>{{ cartItem.store.name }}</h4>
    </div>
    <hr />

    <order-product-card
      v-for="(product, index) in cartItem.cart_products"
      :key="index"
      :product="product"
      @deleteProduct="
        deleteProductInCart({
          cartId: cartItem.id,
          cartItemId: product.id,
          productInCartId: product.cart_item_id,
        })
      "
    ></order-product-card>

    <hr />

    <div class="order-info__prices">
      <div class="order-info__price d-flex justify-content-between mb-2">
        <span class="font-weight-bold text-muted">
          {{ $t("payment.sum") }}
        </span>
        <span class="font-weight-bold text-muted">
          {{ +cartItem.sub_total_amount.toFixed(2) }}
          {{ $t("main.sar") }}
        </span>
      </div>
      <div class="order-info__price d-flex justify-content-between mb-2">
        <span class="font-weight-bold text-muted">
          {{ $t("payment.tax") }}
        </span>
        <span class="font-weight-bold text-muted">
          {{ cartItem.tax_amount }}
          {{ $t("main.sar") }}
        </span>
      </div>
      <div class="order-info__price d-flex justify-content-between mb-2">
        <span class="font-weight-bold text-muted">
          {{ $t("payment.delivery_cost") }}
        </span>
        <span class="font-weight-bold text-muted">
          {{ cartItem.delivery_cost || 0 }}
          {{ $t("main.sar") }}
        </span>
      </div>
      <div
        class="order-info__price d-flex justify-content-between mb-2"
        v-if="cartItem.discount_amount"
      >
        <span class="font-weight-bold text-muted">
          {{ $t("payment.discountAmount") }}
        </span>
        <span class="font-weight-bold text-muted">
          {{ cartItem.discount_amount }}
          {{ $t("main.sar") }}
        </span>
      </div>
      <hr />
      <div class="order-info__price d-flex justify-content-between mb-2">
        <h4 class="font-weight-bold text-muted">
          {{ $t("payment.total") }}
        </h4>
        <h4 class="font-weight-bold text-muted">
          {{ totalAmount }}
          {{ $t("main.sar") }}
        </h4>
      </div>
    </div>
  </b-card>
</template>

<script>
import orderProductCard from "./orderProductCard.vue";
import cartFunctions from "@/mixins/cartFunctions";

export default {
  components: { orderProductCard },
  mixins: [cartFunctions],
  computed: {
    cartItem() {
      return this.$store.getters["cart/getStoreDetailsAndProducts"](
        this.$route.params.id
      );
    },
    totalAmount() {
      return this.$store.getters["cart/getCartTotal"](this.$route.params.id);
    },
  },
};
</script>

<style>
</style>