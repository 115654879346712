<template>
  <label
    class="select-card base-card d-flex justify-content-between align-items-center"
    :class="{
      'active-card': currentValue == value,
    }"
    :for="item.id"
    @keyup.enter="$emit('input', currentValue)"
    @click="$emit('input', currentValue)"
    tabindex="0"
  >
    <b-form-radio
      class="radio-payment-actions d-none"
      :value="currentValue"
      :validate="'required'"
      name="plain-inline"
      plain
      :id="item.id"
      @change="$emit('input', $event)"
    >
    </b-form-radio>
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
    valueKey: {
      type: String,
      default: "id",
    },
  },
  computed: {
    currentValue() {
      return this.valueKey == "object" ? this.item : this.item[this.valueKey];
    },
  },
};
</script>

<style lang="scss">
.base-card {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e2e2;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  margin-top: 0.5rem;
  &.select-card {
    cursor: pointer;
    &:focus-visible {
      border: 1px solid var(--iq-primary) !important;
    }
  }
  &.active-card {
    border: 1px solid var(--iq-primary) !important;
    background-color: #e4eae9 !important;
  }
}
</style>