<template>
  <div class="">
    <header class="d-flex justify-content-between align-content-center">
      <h4 class="text-primary">{{ $t("payment.myAddress") }}</h4>
      <app-button style="width: max-content" @click.native="openAddressModal">
        {{ $t("payment.addNewAddress") }}
      </app-button>
    </header>
    <address-card
      v-for="address in addressesList"
      :key="address.id"
      :address="address"
      :selectedMode="selectedMode"
      @edit="openEditAddressModal"
      @delete="deleteAddress"
    ></address-card>

    <address-modal :address="addressToEdit"></address-modal>
  </div>
</template>

<script>
import { core } from "@/config/pluginInit";
import AppButton from "../buttons/appButton.vue";
import AddressModal from "./addressModal.vue";
import addressCard from "./addressCard.vue";
export default {
  components: { addressCard, AppButton, AddressModal },
  props: {
    selectedMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addressToEdit: {},
    };
  },
  computed: {
    addressesList() {
      return this.$store.getters["address/addressesList"];
    },
  },
  methods: {
    async openAddressModal() {
      this.addressToEdit = {};
      try {
        await this.$store.dispatch("address/getUserCoordinates");
        this.$bvModal.show("address-modal");
      } catch (err) {
        core.showSnackbar("error", err.message);
      }
    },
    openEditAddressModal(address) {
      this.addressToEdit = address;
      this.$store.commit("address/UPDATE_USER_COORDINATES", {
        lat: +address.lat,
        lng: +address.long,
      });
      this.$bvModal.show("address-modal");
    },
    async deleteAddress(addressId) {
      try {
        const { data } = await this.$store.dispatch(
          "address/deleteAddress",
          addressId
        );
        core.showSnackbar("success", data.message);
      } catch (err) {
        core.showSnackbar("error", err.message);
      }
    },
  },
  created() {
    this.$store.dispatch("address/getAddresses");
  },
};
</script>

<style>
</style>