<template>
  <component
    :is="wrapper"
    v-model="selectedAddress"
    class="base-card d-flex justify-content-between align-items-center"
    :item="selectedMode ? address : ''"
  >
    <div class="d-flex flex-column">
      <p class="text-black mb-0 font-weight-bold">{{ address.title }}</p>
      <p class="text-muted mb-0">{{ address.address }}</p>
    </div>
    <div class="address-actions">
      <button
        class="edit-old-address"
        @click="$emit('edit', address)"
        tabindex="0"
      >
        <i class="las la-edit"></i>
      </button>
      <button
        class="delete-old-address"
        @click="$emit('delete', address.id)"
        tabindex="0"
      >
        <i class="las la-trash-alt"></i>
      </button>
    </div>
  </component>
</template>

<script>
import selectCard from "../selectCard.vue";
export default {
  components: { selectCard },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    selectedMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    wrapper() {
      return this.selectedMode ? "select-card" : "div";
    },
    selectedAddress: {
      get() {
        return this.$store.state.address.selectedAddress;
      },
      set(val) {
        this.$store.commit("address/SET_SELECTED_ADDRESS", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background-color: var(--iq-bg-light-color) !important;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}

.address-detail {
  color: #777777 !important;
  font-size: 1.2rem;
  font-weight: 500;

  p {
    margin: 0;
  }
}
.address-actions {
  button {
    background: transparent;
    border: none;

    i {
      font-size: 1.5rem;
    }
    &:focus-visible {
      outline: 1px solid #00443d !important;
    }
  }
  .edit-old-address {
    color: #00443d;
    i {
      font-size: 1.5rem;
    }
  }
  .delete-old-address {
    color: #d62020;
  }
}
</style>


